import L from "leaflet"
import 'leaflet.gridlayer.googlemutant';
import 'leaflet/dist/leaflet.css'

const initInteractiverMasterplan = () => {
    // look for a document ID of #leafletMap
    const theMapWrapper = document.getElementById('leafletMapWrapper')
    const theMapEl = document.getElementById('leafletMap')

    const amenityLayerControls = document.querySelectorAll('[data-toggle-layer]')

    // helper function to calculate the pixel distance between two points
    const calculateDistance = function(x1, y1, x2, y2) {
        const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
        return distance
    }

    if (theMapEl) {
        const mapType = 'google' // 'google' or 'osm'
        // define the map centre point based on user-given data attributes
        const mapLat = theMapEl.getAttribute('data-map-center-lat')
        const mapLng = theMapEl.getAttribute('data-map-center-lng')
        const mapZoom = theMapEl.getAttribute('data-map-zoom') ?? 15
        // define the community map layer bounds
        const displayLotShapes = window.lanserCommunity.displayLotShapes ?? false
        const baseMapLayerBounds = window.lanserCommunity.base.baseMapLayerBounds ?? []
        const baseMapScaleFactor = window.lanserCommunity.base.imgScaleFactor ?? 1
        const baseMapImageSrc = window.lanserCommunity.base.imgSrc ?? ''
        const baseMapWidth = Number(window.lanserCommunity.base.imgWidth) ?? 1
        const baseMapHeight = Number(window.lanserCommunity.base.imgHeight) ?? 1
        const baseMapOpacity = window.lanserCommunity.base.imgOpacity ?? 1
        const amenities = window.lanserCommunity.amenities ?? []

        if (baseMapLayerBounds.length < 1) {
            console.error('Whoops! No map layer bounds provided in CMS!')
            return
        }

        // Determine all unique amenity categories from the amenities array, 
        // so we can create a layerGroup for each category
        const allAmenityCategories = Array.from(
            new Set(amenities.map(amenity => JSON.stringify({ title: amenity.category, slug: amenity.categorySlug })))
        ).map(JSON.parse)

        // define all of the final control layergroup objects in a handy object for later
        let mapLayerGroups = {
            lotCircles: L.layerGroup(),
            radiuses: L.layerGroup(),
            releases: L.layerGroup(),
            stages: L.layerGroup(),
            precincts: L.layerGroup(),
        }

        // ...and add a layerGroup for each amenity category
        allAmenityCategories.forEach(cat => {
            const catSlug = 'amenities__' + cat.slug
            mapLayerGroups[catSlug] = L.layerGroup()
        })

        // Also define a function to handle the amenity layer control buttons via external DOM elements
        amenityLayerControls.forEach(el => {
            el.addEventListener('click', function() {
                // console.log('toggleLayerGroup', el.dataset.toggleLayer, mapLayerGroups)
                // const result = toggleLayerGroup(masterplanMap, mapLayerGroups[el.dataset.toggleLayer])
                // if (!result) {
                //     console.error(`Layer group ${el.dataset.toggleLayer} not found in layerGroups (${Object.keys(mapLayerGroups).join(', ')})`)
                // }
                const result = toggleAmenityLayerGroup(masterplanMap, el.dataset.toggleLayer)
                if (!result) {
                    console.error(`Layer group ${el.dataset.toggleLayer} not found in layerGroups (${Object.keys(mapLayerGroups).join(', ')})`)
                }
            })
        })

        // ====================

        // ----- MAP TILE LAYER OPTS ----- //
        let tileLayer = null

        // Note: Maps created in Google Cloud Console NEED to use the "raster" rendering type, NOT "vector".. 
        // otherwise we just get a grey map with no errors and everyone has a bad time trying to debug WTF is going on.  See:
        // https://gitlab.com/IvanSanchez/Leaflet.GridLayer.GoogleMutant/-/issues/144
        // console.log("map ID", window.lanserCommunity.base.mapId)

        switch (mapType) {
            case 'google':
                // Add Google Mutant layers
                tileLayer = L.gridLayer.googleMutant({ 
                    type: 'roadmap',
                    version: 'beta',
                    mapId: window.lanserCommunity.base.mapId,
                })

                break
            case 'osm':
                // Add OSM tile layer
                tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    // attribution: '&copy <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                })
                break
            default:
                // tings
                break
        }

        // Function to toggle layer groups within a map
        const toggleLayerGroup = function(map, layerGroup, force = null) {
            if (typeof layerGroup === 'undefined') {
                console.error('toggleLayerGroup: layerGroup is undefined')
                return false
            }
            // if force is true or false, we force the layerGroup to be enabled or disabled and exit.
            if (force !== null) {
                if (force) {
                    map.addLayer(layerGroup)
                } else {
                    map.removeLayer(layerGroup)
                }
                return true
            }
            // otherwise, we toggle the layerGroup based on whether it is already enabled or not
            if (map.hasLayer(layerGroup)) {
                map.removeLayer(layerGroup)
            } else {
                map.addLayer(layerGroup)
            }
            return true
        }

        // Function to toggle a specific amenity layerGroup, and also 
        // turn all other amenity layerGroups (the ones starting with 'amenities__') off
        const toggleAmenityLayerGroup = function (
          map,
          layerGroup = 'amenities__display-village'
        ) {
          // loop the mapLayerGroups object and where the key does not start with 'amenities__', turn off the layerGroup
          Object.keys(mapLayerGroups).forEach((key) => {
            if (key !== layerGroup && key.startsWith('amenities__')) {
              toggleLayerGroup(map, mapLayerGroups[key], false);
            }
          });
          // turn on the layerGroup that was clicked
          if (layerGroup) {
            const theLayerGroup = mapLayerGroups[layerGroup];
            toggleLayerGroup(map, theLayerGroup, true);
          }
          return true;
        };

        // ----- MAP LAYER ----- //
        // create a new map object at the window level
        // NOTE: we hard-code the initial map creation zoom level, as it affects the imgBounds calc below.. 
        // then once we have added the layers, we re-set the zoom level as defined in the CMS-specified zoom attribute
        window.masterplanMap = L.map('leafletMap',{
            scrollWheelZoom: false,
            center: [mapLat, mapLng],
            minZoom: window.lanserCommunity.base.zoomMin,
            maxZoom: window.lanserCommunity.base.zoomMax,
            zoom: 18,
            zoomSnap: 0.5,
            layers: [tileLayer, mapLayerGroups.radiuses, mapLayerGroups.stages, mapLayerGroups.precincts],
        })

        // ----- BASE/COMMUNITY MAP IMAGE ----- //
        // If there are two latLngBounds provided in baseMapLayerBounds, we use both to calculate the bounding box of 
        // the map image, otherwise we use the first latLngBounds, the map width/height and the imgScaleFactor to calculate the bounding box of the map image
        let imgBounds = []
        if (baseMapLayerBounds.length === 2) {
            // use both latLngBounds to calculate the bounding box of the map image
            imgBounds = L.latLngBounds([
                baseMapLayerBounds[0],
                baseMapLayerBounds[1],
            ])
        } else {
            // use the first latLngBounds anc calc the rest. We calculate the resulting lat/lng of the bottom corner using 
            // various leaflet methods to get the map container points and then convert back to latLng

            // the containerPoint of the top left and bottom right corners of the map image
            const originContainerPoint = masterplanMap.latLngToContainerPoint(baseMapLayerBounds[0], theMapEl)
            const destinationContainerPoint = L.point(
                originContainerPoint.x + (baseMapWidth * baseMapScaleFactor),
                originContainerPoint.y + (baseMapHeight * baseMapScaleFactor)
            )

            // the latLng of the bottom right corner of the map image
            const destinationLatLngCoords = masterplanMap.containerPointToLatLng(destinationContainerPoint)

            imgBounds = L.latLngBounds([
                baseMapLayerBounds[0],
                destinationLatLngCoords
            ])
        }

        // ----- RADIUSES & DISTANCE MARKERS----- //
        // 4 radius circles (1km, 3km, 5km, 10km) emanating from the centre of the map
        const radius1km = L.circle([mapLat, mapLng], { radius: 1000, color: '#C06C39', fill: false, opacity: 0.5 })
        const radius3km = L.circle([mapLat, mapLng], { radius: 3000, color: '#C06C39', fill: false, opacity: 0.5 })
        const radius5km = L.circle([mapLat, mapLng], { radius: 5000, color: '#C06C39', fill: false, opacity: 0.5 })
        const radius10km = L.circle([mapLat, mapLng], { radius: 10000, color: '#C06C39', fill: false, opacity: 0.5 })

        radius1km.addTo(mapLayerGroups.radiuses)
        radius3km.addTo(mapLayerGroups.radiuses)
        radius5km.addTo(mapLayerGroups.radiuses)
        radius10km.addTo(mapLayerGroups.radiuses)

        // 8 markers, one for each radius circle, at the south and north points of the circle
        const latLng1kmNorth = _moveLatLng(mapLat, mapLng, 1000, 'north')
        const latLng3kmNorth = _moveLatLng(mapLat, mapLng, 3000, 'north')
        const latLng5kmNorth = _moveLatLng(mapLat, mapLng, 5000, 'north')
        const latLng10kmNorth = _moveLatLng(mapLat, mapLng, 10000, 'north')
        const latLng1kmSouth = _moveLatLng(mapLat, mapLng, 1000, 'south')
        const latLng3kmSouth = _moveLatLng(mapLat, mapLng, 3000, 'south')
        const latLng5kmSouth = _moveLatLng(mapLat, mapLng, 5000, 'south')
        const latLng10kmSouth = _moveLatLng(mapLat, mapLng, 10000, 'south')

        const radius1kmMarkerNorth = L.marker([latLng1kmNorth.lat, latLng1kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-1km">1km</span>'}) })
        const radius1kmMarkerSouth = L.marker([latLng1kmSouth.lat, latLng1kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-1km">1km</span>'}) })
        const radius3kmMarkerNorth = L.marker([latLng3kmNorth.lat, latLng3kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-3km">3km</span>'}) })
        const radius3kmMarkerSouth = L.marker([latLng3kmSouth.lat, latLng3kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-3km">3km</span>'}) })
        const radius5kmMarkerNorth = L.marker([latLng5kmNorth.lat, latLng5kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-5km">5km</span>'}) })
        const radius5kmMarkerSouth = L.marker([latLng5kmSouth.lat, latLng5kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-5km">5km</span>'}) })
        const radius10kmMarkerNorth = L.marker([latLng10kmNorth.lat, latLng10kmNorth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-10km">10km</span>'}) })
        const radius10kmMarkerSouth = L.marker([latLng10kmSouth.lat, latLng10kmSouth.lng], { icon: L.divIcon({html: '<span class="radius-marker marker-10km">10km</span>'}) })

        radius1kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius1kmMarkerNorth.addTo(mapLayerGroups.radiuses)
        radius3kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius3kmMarkerNorth.addTo(mapLayerGroups.radiuses)
        radius5kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius5kmMarkerNorth.addTo(mapLayerGroups.radiuses)
        radius10kmMarkerSouth.addTo(mapLayerGroups.radiuses)
        radius10kmMarkerNorth.addTo(mapLayerGroups.radiuses)

        // ----- STAGES ----- //
        // Loop through window.lanserCommunity.stages and add their image to the map layers
        // NOTE: this is currently unused as we are using precincts instead, as per client request (14 Oct)
        for(let i = 0; i < window.lanserCommunity.stages.length; i++) {
            
            // set the image url
            // const imageUrl = window.lanserCommunity.stages[i].imageUrl
            // define the image/svg overlay for the specific stage
            // const stage = L.imageOverlay(imageUrl, imgBounds, {
            //     opacity: 1,
            //     zIndex: 1 + i,
            // })

            // or... set an svgElement by retrieving the image from the DOM
            const svgElement = document.querySelector(`svg.stagemap_${i}`)
            const stage = L.svgOverlay(svgElement, imgBounds, {
                opacity: 1,
                zIndex: 1 + i,
            })

            // add a new stage layerGroup to the mapLayerGroups 'stages' object
            stage.addTo(mapLayerGroups.stages)
        }

        // ----- PRECINCTS ----- //
        // Loop through window.lanserCommunity.precincts and add their image to the map layers
        for(let i = 0; i < window.lanserCommunity.precincts.length; i++) {
            const precinctItem = window.lanserCommunity.precincts[i]
            
            // set the image url
            // const imageUrl = window.lanserCommunity.precincts[i].imageUrl
            // define the image/svg overlay for the specific precinct
            // const precinct = L.imageOverlay(imageUrl, imgBounds, {
            //     opacity: 1,
            //     zIndex: 1 + i,
            // })

            // or... set an svgElement by retrieving the image from the DOM
            const svgElement = document.querySelector(`svg.precinctmap[data-precinct-slug="${precinctItem.slug}"]`)
            if (!svgElement) {
                console.warn(`No valid SVG element found for precinct '${precinctItem.slug}'`)
                continue
            }
            const precinct = L.svgOverlay(svgElement, imgBounds, {
                opacity: 1,
                zIndex: 1 + i,
                bubblingMouseEvents: false,
            })

            // add a new precinct layerGroup to the mapLayerGroups 'precinct' object
            precinct.addTo(mapLayerGroups.precincts)
        }

        // ----- RELEASES ----- //
        // Loop through window.lanserCommunity.releases and add their image to the map layers
        if (displayLotShapes) {
            for(let i = 0; i < window.lanserCommunity.releases.length; i++) {
                const releaseItem = window.lanserCommunity.releases[i]
                
                // set the image url
                // const imageUrl = window.lanserCommunity.releases[i].imageUrl
                // define the image/svg overlay for the specific release
                // const release = L.imageOverlay(imageUrl, imgBounds, {
                //     opacity: 1,
                //     zIndex: 1 + i,
                // })

                // or... set an svgElement by retrieving the image from the DOM
                const svgElement = document.querySelector(`svg.releasemap[data-release-slug="${releaseItem.slug}"]`)
                if (!svgElement) {
                    console.warn(`No valid SVG element found for release '${releaseItem.slug}'`)
                    continue
                }
                const release = L.svgOverlay(svgElement, imgBounds, {
                    opacity: 1,
                    zIndex: 1 + i,
                })

                // add a new release layerGroup to the mapLayerGroups 'releases' object
                release.addTo(mapLayerGroups.releases)
            }
            // enable releases layerGroup on the map by default
            window.masterplanMap.addLayer(mapLayerGroups.releases)
        }

        // ----- AMENITIES ----- //
        // Loop through the amenities and create a marker for each
        for(let i = 0; i < amenities.length; i++) {
            const amenityItem = amenities[i]

            const amenityMarker = L.marker([amenityItem.lat, amenityItem.lng], {
                icon: L.divIcon({
                    className: `amenity-icon ${amenityItem.icon}`,
                    html: `<div class="pin"><svg><use href="#amenity-${amenityItem.icon}"></use></svg><span class="icon-bg"></span></div>`,
                    iconSize: [56, 56],
                    iconAnchor: [23, 56],
                }),
            })
                // .bindPopup(amenity.title)
                .on('click', function() {
                    masterplanControls.showAmenityDetails(amenityItem.slug)
                })

            // add a new amenity layerGroup to the appropriate namespaced mapLayerGroups 'amenities__{categorySlug}' object
            const catName = 'amenities__' + amenityItem.categorySlug
            amenityMarker.addTo(mapLayerGroups[catName])
        }

        // ...then turn them all off on map after 0.1s.. lol
        setTimeout(() => {
            toggleAmenityLayerGroup(masterplanMap)
        }, 100)

        // ----- LOTS (circles) ----- //
        // (optional/fallback) lat/lng clickable circles for each lot (in case SVG outlines are not available)
        if (!displayLotShapes) {
            const lotCircles = generateLotCircles(masterplanMap, lanserCommunity.lots)
        }

        // update Jan 31st 2025: render the lots anyway LOLZ
        const lotCircles = generateLotCircles(masterplanMap, lanserCommunity.lots)
        lotCircles.forEach(circle => {
            mapLayerGroups.lotCircles.addLayer(circle)
        })

        // ----- BASE/COMMUNITY MAP IMAGE ----- //
        // create the community base layer image, add it to the map & 
        // re-set the zoom level as defined in the data attribute
        const communityBaseLayer = L.imageOverlay(baseMapImageSrc, imgBounds, {
            opacity: baseMapOpacity,
            zIndex: 1,
        })
        communityBaseLayer.addTo(window.masterplanMap)
        window.masterplanMap.setZoom(mapZoom)

        // ----- LAYERS CONTROL ----- //
        // finally create a new Layers Control and add it to the map, 
        // with the mapLayerGroup stages / precincts as the default 'on'
        const layersControl = L.control.layers(null, {
            "Show Stages": mapLayerGroups.stages,
            "Show Precincts": mapLayerGroups.precincts,
        }, {
            collapsed: true,
        }).addTo(masterplanMap)

        // if displayLotShapes is true, add the 'releases' layerGroup to the layersControl
        if (displayLotShapes) {
            layersControl.addOverlay(mapLayerGroups.releases, 'Show Releases')
        }

        // afterwards, add each of the namespaced 'amenities__{categorySlug}' layerGroups to the layersControl
        allAmenityCategories.forEach(cat => {
            const catSlug = 'amenities__' + cat.slug
            // layersControl.addOverlay(mapLayerGroups[catSlug], cat.title) // adds to the layersControl pane (in default OFF state)
            window.masterplanMap.addLayer(mapLayerGroups[catSlug], cat.title) // adds to the map itself (in default ON state)
        })

        // ----- RESIZE OBSERVER ----- //
        // Resize observer to handle map resizing
        function initResizeObserver() {
            const resizeObserver = new ResizeObserver(() => {
                // console.log('resizing')
                masterplanMap.invalidateSize()
            })

            return resizeObserver
        }

        // ----- ZOOM LISTENER ----- //
        // Listen for the zoomend event
        function initZoomListener() {
            masterplanMap.on('zoomend', () => {
                const zoomLevel = masterplanMap.getZoom()
                // console.log(`Current zoom level: ${zoomLevel}`)
                
                // zoom Level is further away, show the precincts
                if (zoomLevel < window.lanserCommunity.base.precinctZoomCutoff) {
                    // Enable precincts and disable releases & lot circles
                    toggleLayerGroup(masterplanMap, mapLayerGroups.precincts, true)
                    toggleLayerGroup(masterplanMap, mapLayerGroups.releases, false)
                    toggleLayerGroup(masterplanMap, mapLayerGroups.lotCircles, false)

                    // also re-bind markers to each precinct
                    bindPrecinctMarkers(true, zoomLevel)

                } else {
                    // Enable releases/lot numbers and disable precincts & precinct markers
                    toggleLayerGroup(masterplanMap, mapLayerGroups.precincts, false)
                    toggleLayerGroup(masterplanMap, mapLayerGroups.releases, true)
                    toggleLayerGroup(masterplanMap, mapLayerGroups.lotCircles, true)
                    bindPrecinctMarkers(false, zoomLevel)
                }
            })
        }

        // ----- CMD/CTRL SCROLL LISTENER ----- //
        function initScrollListener() {
            // Add custom scroll zoom behavior
            let isCtrlCmdPressed = false

            // reference the helper/tooltip element
            const scrollTip = document.getElementById('mapScrollTooltip')

            // Listen for keydown and keyup events to track the Control/Command key
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Control' || e.key === 'Meta') { isCtrlCmdPressed = true }
            })
            document.addEventListener('keyup', (e) => {
                if (e.key === 'Control' || e.key === 'Meta') { isCtrlCmdPressed = false }
            })

            // Enable scroll zoom only when the Control/Command key is pressed
            L.DomEvent.on(masterplanMap.getContainer(), 'wheel', (e) => {
                if (isCtrlCmdPressed) {
                    masterplanMap.scrollWheelZoom.enable() // Temporarily enable scroll zoom
                    scrollTip.classList.remove('active')
                    // Disable it after zoom, with a timeout of 250ms
                    masterplanMap.once('zoomend', () => {
                        setTimeout(() => {
                            masterplanMap.scrollWheelZoom.disable()
                        }, 250)
                        // If you want the tooltip to return after a delay, uncomment the following:
                        // setTimeout(() => {
                        //     scrollTip.classList.add('active')
                        // }, 1250)
                    })
                }
            })
        }

        // ----- LOT SHAPES CLICK HANDLER ----- //
        const initLotShapesHandler = function(map) {
            const releaseMaps = []
            const releaseMapEls = map.querySelectorAll('svg[data-release]')
            releaseMapEls.forEach(el => {
                // only select the first-level children elements of each releaseMapEl, 
                // where each child is a path, polygon, rect or g element
                const lotShapeEls = el.querySelectorAll('path, polygon, rect, g')

                if (lotShapeEls.length > 0) {
                    const lotShapes = []
                    lotShapeEls.forEach(shape => {
                        // calculate the lotId from the shape's ID i.e. "LOT_2756_Pine_Valley_R2"
                        const lotData = _lotNameDeCombobulator(shape.id)
                        lotShapes.push({
                            el: shape,
                            data: lotData,
                        })
                    })
                    // add a click handler to each shape, as well as a status CSS class, based on the matching lot in lanserCommunity.lots
                    lotShapes.forEach(shape => {
                        shape.el.addEventListener('click', function() {
                            masterplanControls.showLotDetails(shape.data)
                        })
                        // add a status CSS class to the shape, based on the matching lot in lanserCommunity.lots
                        const lot = lanserCommunity.lots.find(lot => lot.lotNo == shape.data.lotNo)
                        // console.log('looked for lot', shape.data.lotNo, 'in', lanserCommunity.lots, 'got', lot)
                        if (lot) {
                            shape.el.classList.add(`status-${lot.status.toLowerCase()}`)
                        }
                    })
                    // push it all up to the releaseMaps array
                    releaseMaps.push({
                        id: el.dataset.releaseId,
                        slug: el.dataset.releaseSlug,
                        lotShapes: lotShapes,
                    })
                }
            })
        }

        // ----- PRECINCT SHAPES CLICK HANDLER (not used, as markers are used instead - see bindPrecinctMarkers) ----- //
        const initPrecinctShapesHandler = function(map) {
            const precinctMapEls = map.querySelectorAll('svg[data-precinct]')
            precinctMapEls.forEach(el => {
                el.addEventListener('click', function() {
                    console.log('clicked precinct', el.dataset)
                    // reference the masterplan control dataset from in the DOM, 
                    // to fire off the appropriate zoomToArea function
                    const controlEl = document.querySelector(`.masterplan-control-button[data-precinct-id="${el.dataset.precinctId}"]`)
                    if (controlEl) {
                        masterplanControls.zoomToArea(controlEl.dataset.lat, controlEl.dataset.lng, window.lanserCommunity.base.precinctZoomCutoff)
                    } else {
                        console.warn(`No matching control element found for precinct '${el.dataset.precinctId}'`)
                    }
                })
            })
        }

        // ----- PRECINCT MARKERS CLICK/HOVER HANDLER ----- //
        const bindPrecinctMarkers = function (bind = true, zoomLevel = 18) {
            if (!bind) {
                mapLayerGroups.precinctMarkers.clearLayers()
                return
            }
            // Clear the existing markers layer group if it exists, and
            if (mapLayerGroups.precinctMarkers) {
                mapLayerGroups.precinctMarkers.clearLayers()
            } else {
                mapLayerGroups.precinctMarkers = L.layerGroup().addTo(masterplanMap)
            }
        
            // Loop through precinct layers and add markers
            mapLayerGroups.precincts.eachLayer(function (layer) {
                const precinctSlug = layer._image.dataset.precinctSlug
                const precinctItem = window.lanserCommunity.precincts.find(
                    (precinct) => precinct.slug === precinctSlug
                )
                
                // only proceed if a matching precinct was found in the lanserCommunity.precincts array
                if (precinctItem) {

                    // grab a reference to the precinct outline layer on the map in case we need it
                    // const precinctOutlineEl = document.querySelector(`.precinctmap[data-precinct-slug="${precinctItem.slug}"]`)

                    // dynamic Icon size based on the zoom level (higher zoom = larger icon)
                    const iconSizes = [
                        { zoom: 18, size: [150, 50] },
                        { zoom: 16, size: [125, 40] },
                        { zoom: 14, size: [100, 30] },
                        { zoom: 12, size: [100, 30] },
                    ]
                    const iconAnchors = [
                        { zoom: 18, anchor: [75, 25] },
                        { zoom: 16, anchor: [62.5, 20] },
                        { zoom: 14, anchor: [50, 15] },
                        { zoom: 12, anchor: [50, 15] },
                    ]
                    const iconSize = iconSizes.find(size => zoomLevel >= size.zoom)
                    const iconAnchor = iconAnchors.find(anchor => zoomLevel >= anchor.zoom)

                    // Create the DivIcon for the marker
                    const markerIcon = L.divIcon({
                        className: `precinct-marker ${precinctItem.slug}`,
                        html: `<div class="precinct-marker-content">${precinctItem.title}</div>`,
                        iconSize: iconSize.size,
                        iconAnchor: iconAnchor.anchor,
                    })
        
                    // Create the marker and add it to the layer group
                    const marker = L.marker([precinctItem.lat, precinctItem.lng], {
                        icon: markerIcon,
                        bubblingMouseEvents: false,
                        precinctData: precinctItem
                    })

                    // click handler
                    marker.on("click", function (e) {
                        const precinctData = e.target.options.precinctData;
                        masterplanControls.zoomToArea(
                            precinctData.lat,
                            precinctData.lng,
                            window.lanserCommunity.base.precinctZoomCutoff
                        )
                    })

                    // mouseover/out hover effect handlers
                    marker.on('mouseover', function (e) {
                        const precinctData = e.target.options.precinctData;
                        const precinctOutlineEl = document.querySelector(`.precinctmap[data-precinct-slug="${precinctData.slug}"]`)
                        if (precinctOutlineEl) {
                            precinctOutlineEl.classList.add('hovering')
                        }
                    }).on('mouseout', function (e) {
                        const precinctData = e.target.options.precinctData;
                        const precinctOutlineEl = document.querySelector(`.precinctmap[data-precinct-slug="${precinctData.slug}"]`)
                        if (precinctOutlineEl) {
                            precinctOutlineEl.classList.remove('hovering')
                        }
                    })
        
                    // Finally add the marker to the precinct markers layer group
                    mapLayerGroups.precinctMarkers.addLayer(marker)
                } else {
                    console.warn(
                        `No matching precinct found for slug '${precinctSlug}' when binding markers`
                    )
                }
            })
        }
        
        if (theMapWrapper) {
            // init scroll listener, and zoom listener
            initZoomListener()
            initScrollListener()
            // init the lot shapes & precinct shapes click-handlers/marker handlers
            initLotShapesHandler(theMapWrapper)
            // initPrecinctShapesHandler(theMapWrapper) // disabled for now, as markers are used instead
            bindPrecinctMarkers()
            // init the resize observer
            const resizeObserver = initResizeObserver()
            resizeObserver.observe(theMapWrapper)
            masterplanMap.invalidateSize() // initial resize
        }

        // TESTING: lat/lng demolots from Christie
        // demoLots.forEach(lot => {
        //     L.circle([lot.lat, lot.lng])
        //         .addTo(masterplanMap)
        //         .bindPopup(`Lot ${lot.lotNo}`)
        // })
    }
}

// misc functions to control the map from outside the module
const masterplanControls = {
    zoomToArea: function(lat, lng, zoom = window.window.lanserCommunity.base.precinctZoomCutoff) {
        console.log(`panning to ${lat}, ${lng} at zoom ${zoom} + 1`)
        const zoomDepth = ((zoom + 1) > window.lanserCommunity.base.zoomMax) ? window.lanserCommunity.base.zoomMax : (zoom + 1)
        window.masterplanMap.setView([lat, lng], zoomDepth, { animate: true })
    },
    showLotDetails: function(lotData) {
        // determine the lot to show based on the lotData supplied
        const selectedLot = window.lanserCommunity.lots.find(lot => {
            // originally looked for a relasename in the ltoData, but now we're looking for the communityName..
            // return (lot.lotNo == lotData.lotNo) && (lot.releaseSlug.toLowerCase().includes(lotData.releaseName.toLowerCase()))

            // gotta kebabize the lot.communityHandle.. eww..
            // const communitySlug = utils.kebabize(lot.communityHandle)
            // return (lot.lotNo == lotData.lotNo) && (communitySlug.includes(lotData.communityName.toLowerCase()))
            return lot.lotNo == lotData.lotNo
        })
        if (selectedLot) {
            // dispatch custom window event 'showlotdetails' with the lotId as the detail
            window.dispatchEvent(new CustomEvent('showlotdetails', { detail: selectedLot.id }))
        } else {
            console.warn(`No matching lot number ${lotData.lotNo} found in release '${lotData.communityName}'`)
        }
    },
    showAmenityDetails: function(amenitySlug) {
        console.log('showAmenityDetails', amenitySlug)
        // dispatch custom window event 'showamenitydetails' with the lotId as the detail
        window.dispatchEvent(new CustomEvent('showamenitydetails', { detail: amenitySlug }))
    }
}

// ----- LOT CIRCLES ----- //
// render the lot circles on the map
const generateLotCircles = function(map, lots, interactive = false) {
    const lotCircles = []
    for(let i = 0; i < lots.length; i++) {
        const lot = lots[i]

        let fillColor
        switch (lot.status.toLowerCase()) {
            case 'available':
                fillColor = '#b5d358'
                break
            case 'on hold':
                fillColor = '#ffc84b'
                break
            case 'deposit taken':
                fillColor = '#ffc84b'
                break
            case 'sold':
                fillColor = '#F54B4B';
                break
            default:
                fillColor = '#ffc84b'
                break
        }

        if (lot.lat && lot.lng) {
            const circle = L.circle([lot.lat, lot.lng], {radius: 1.5, fillColor: fillColor, color: fillColor, fillOpacity: 1 })
                // .bindPopup(`Lot ${lot.lotNo}`)
                lotCircles.push(circle)

            if (interactive) {
                circle.on('click', function() {
                    masterplanControls.showLotDetails(lot)
                })
            }
        }
    }
    return lotCircles
}

// Helper function to parse the lotId into a lotNo, releaseName & releaseId
const _lotNameDeCombobulator = function(lotId) {
    // LOT_2756_Pine-Valley_R2 -> { lotNo: 2756, name: Pine-Valley, release: R2 }
    // Update Nov 2024: changed the format to LOT_2756_aston-hills -> { lotNo: 2756, name: aston-hills }
    const parts = lotId.split('_')
    if (parts.length !== 3) {
        console.warn(`Invalid lotId format: ${lotId} - expected 4 parts, got ${parts.length}`)
    }
    return {
        lotNo: parts[1] ?? null,
        communityName: parts[2] ?? null, // used to be 'releaseName' but... client..
        // releaseId: parts[3] ?? null,
    }
}

// Helper function to move a point by a given distance and direction
const _moveLatLng = function(lat, lng, distance, direction) {
    const earthRadius = 6371000 // meters
    const rad = Math.PI / 180   // Convert degrees to radians

    // Convert latitude and longitude to numbers (in case they are strings)
    lat = parseFloat(lat)
    lng = parseFloat(lng)
    distance = parseFloat(distance)

    // Adjust latitude
    if (direction === 'north' || direction === 'south') {
        const newLat = lat + (distance / 111320) * (direction === 'north' ? 1 : -1)
        return { lat: newLat, lng: lng }
    }

    // Adjust longitude
    else if (direction === 'east' || direction === 'west') {
        const newLng = lng + (distance / (111320 * Math.cos(lat * rad))) * (direction === 'east' ? 1 : -1)
        return { lat: lat, lng: newLng }
    }

    // If direction is invalid
    else {
        console.error('Invalid direction specified. Use "north", "south", "east", or "west".')
        return null
    }
}


export default { initInteractiverMasterplan, masterplanControls }
